import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/service/product/product.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { Autoplay, SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation, Autoplay]);
@Component({
  selector: 'app-singleproduct',
  templateUrl: './singleproduct.component.html',
  styleUrls: ['./singleproduct.component.css'],
  providers: [NgbModalConfig, NgbModal],
})
export class SingleproductComponent implements OnInit {
  BaseUrl = '';
  productData: any;
  categoryDataList = [];
  product_id: any;
  constructor(
    private product: ProductService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private trusturl: DomSanitizer,
    config: NgbModalConfig,
    private modalService: NgbModal,
    @Inject('BASE_IMAGE_URL') _imageurl: any,
    private meta: Meta, private title: Title
  ) {
    this.BaseUrl = _imageurl;
    config.backdrop = 'static';
    config.keyboard = false;
  }

  active = 1;
  code: any;
  ngOnInit(): void {

    this.route.params.subscribe(paramMap => {
      this.product_id = paramMap['_id']
      this.getProductDetail();
      this.getRelatedProduct();
      this.code = this.userdata.isIndia();
    })
    
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  productdata: any;
  productcategoryId: any;
  product_image = [];
  reviewlength: any;
  price: number = 0;
  offerPer: number = 0;
  msg: string = '';
  noAddon: string = '';
  slideConfig = { slidesToShow: 2, slidesToScroll: 2, dots: true };

  reviews = new Array();
  reviewData: any;
  loadProduct(productId: any) {
    this.product_id = productId;
    this.getProductDetail();
  }
  productImage:any;
  getProductDetail() {
    this.spinner.show();
    this.product.getProductDetail({ product_slug: this.product_id }).subscribe(
      (res: any) => {
        this.title.setTitle(`Astro River - ${res.data?.productname}`)
        this.meta.updateTag({ name: 'keywords', content: res.data?.product_keyword })
        this.reviews = res.reviews;
        this.reviewData = res.reviewdata;
        this.setProgress();
        this.spinner.hide();

        this.productData = res.data;
        this.productImage = this.productData.product_image[0]
        // console.log('product',res.data)
        if (res.data.is_direct_price) {
          if (res.data.is_offer_applied) {
            this.price = res.data.price;
            this.offerPer = res.data.offer_percentage;
          }
        }
        this.reviews = res.reviews;
        this.reviewlength = res.reviews.length;
        if (res.reviews.length == 0) {
          this.msg = 'No Reviews yet!';
        }
        // console.log(res.data.addon_detail.length)
        if (res.data.is_addon_associate && res.data.addon_detail.length == 0) {
          this.noAddon = 'No Related Product Avaiable!';
        } else if (!res.data.is_addon_associate) {
          this.noAddon = '';
        }
        this.productcategoryId = res.data.productcategoryId._id;
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }
  setProgress() {
    for (var i = 1; i <= 5; i++) {
      let perc =
        (this.reviewData[`star${i}`] / this.reviewData.totalcount) * 100;
      document.getElementById(`prog-${i}`)!.style.width = `${perc}%`;
    }
  }

  categorydata: any;
  getOrginialPrice(product: any) {
    return this.code
      ? product.original_amount
      : product.usd_original_amount || 0;
  }
  getCurrentPrice(product: any) {
    return this.code ? product.price : product.usd_price || 0;
  }
  latestProduct = new Array();
  originalProduct = new Array();
  featuredProduct: any;
  getRelatedProduct() {
    this.spinner.show();
    this.categorydata = {
      productcategoryId: this.productcategoryId,
      status: true,
      startpoint: 0,
      isFeaturedRequired: true,
      is_astrologer_associate: false,
    };
    this.product.getProductByCategory(this.categorydata).subscribe(
      (res: any) => {
        this.latestProduct = res.data;
        this.featuredProduct = res.featuredProduct;
        // this.originalProduct = res.data;
        // var limit = res.data.length / 3;
        // var rnd = Math.round(limit);
        // limit = rnd < limit ? limit + 1 : rnd;
        // var i = 0;
        // while (i < limit) {
        //   var j = 0;
        //   var start = i * 3;
        //   var temp = [];
        //   while (j < 3) {
        //     temp[j] = res.data[start + j];
        //     j++;
        //   }
        //   this.latestProduct[i] = temp;
        //   i++;
        //}
      },
      (err: any) => {
        //console.log(err);
      }
    );
  }

  /** Add to Cart */
  @ViewChild("headerOne") headerOne:any;
  book(id: any) {
    if (this.userdata.getToken() == (null || '')) {
      this.toast.error('Please Login to use Service', 'Info');
      this.spinner.hide();
      sessionStorage.setItem('product-detail', id);
      this.headerOne?.openFromAnotherComponent()
      return;
    }else if(this.userdata.getEmailVerify() == ('false' || false)){
      sessionStorage.setItem('product-detail', id);
      this.toast.error('Please Verify Email');
      this.router.navigateByUrl('/verify-email-otp')
      return
    } else {
      this.router.navigate(['/order-product', id]);
    }
  }

  open(content: any) {
    this.modalService.open(content);
  }

  config: SwiperOptions = {
    slidesPerView: 4,
    direction: 'horizontal',
    spaceBetween: 5,
    effect: 'slide',
    loop: false,
    scrollbar: false,
    navigation: {
      nextEl: '.left-btn',
      prevEl: '.right-btn',
    },
  };
  relatedProduct: SwiperOptions = {
    slidesPerView: 3,
    direction: 'horizontal',
    spaceBetween: 10,
    effect: 'slide',
    loop: true,
    scrollbar: false,
    navigation: {
      nextEl: '.nav-left',
      prevEl: '.nav-right',
    },
  };

  getPrice(product:any){
    if (product?.is_direct_price){
      return this.code ? product.price : product.usd_price || 0;
    }
    else{
      return this.code ? product?.astrologerList[0].price : product?.astrologerList[0].usd_price || 0;
    }
  }
}
