import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { Autoplay, SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
import { PassDataService } from 'src/app/service/data/pass-data.service';
import { AstroData } from 'src/app/myModels/astro/astro-data';
import { DatePipe } from '@angular/common';
import { LoginService } from 'src/app/service/login/login.service';
import Swal from 'sweetalert2';
SwiperCore.use([Navigation, Autoplay]);
@Component({
  selector: 'app-call-astrologer-list',
  templateUrl: './call-astrologer-list.component.html',
  styleUrls: ['./call-astrologer-list.component.css'],
})
export class CallAstrologerListComponent implements OnInit {
  BaseUrl: string = ''; sectionTitle = 'Top Astrologers'
  dataList: any = new Array();
  isWaitingGoing: boolean = false;
  walletBalance = 0;
  totalPages = 0;
  initPage = 0;
  filterType = 0;
  astroData: AstroData = {
    customerId: undefined,
    is_promotional_allow: false,
    name: undefined,
    call_charges: undefined,
    chat_charges: undefined,
    query_charges: undefined,
    report_charges: undefined,
    experience: undefined,
    total_call_taken: undefined,
    total_chat_taken: undefined,
    total_query_taken: undefined,
    total_report_taken: undefined,
  };
  constructor(
    private orderService: OrderServicesService,
    private userdata: UserdataService,
    private dataService: PassDataService,
    private router: Router,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private trusturl: DomSanitizer,
    private datePipe: DatePipe,
    private loginService: LoginService,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  mul = 2;
  isCallGoing: boolean = false;
  isChatGoing: boolean = false;
  endpoint = '';
  myData: any;
  isLogin = false;
  ngOnInit(): void {
    const url = this.router.url.split('/');
    this.endpoint = url[1];
    this.setBreadChurmb();
    if (this.userdata.getToken().length > 0) {
      this.astroData.customerId = this.userdata.getCustomerId();
      this.getMyProfile()
      if (this.endpoint.includes('promo'))
        this.astroData.is_promotional_allow = true;
    }
    this.isLogin = this.userdata.isLogin();

    if (!this.userdata.isIndia()) {
      this.mul = 1;
    }
    // this.walletBalance = Number(this.userdata.getCurrentWallet()) ?? 0;
    this.getCallAstrologerList();
  }
  breadcrumb = '';
  button_title = '';
  button_icon = '';
  setBreadChurmb() {
    switch (this.endpoint) {
      case 'call-astro-list':
        this.button_icon = 'phone_in_talk';
        this.button_title = 'Call';
        this.breadcrumb = 'talk to astrologer';
        break;
      case 'chat-astro-list':
        this.button_icon = 'chat';
        this.button_title = 'Chat';
        this.breadcrumb = 'chat with astrologer';
        break;
      case 'promo-call-astro-list':
        this.button_icon = 'phone_in_talk';
        this.button_title = 'Call';
        this.breadcrumb = 'promotional astrologer list';
        break;
      case 'promo-chat-astro-list':
        this.button_icon = 'chat';
        this.button_title = 'Chat';
        this.breadcrumb = 'promotional astrologer list';
        break;
      case 'query-astro-list':
        this.button_icon = 'help_center';
        this.button_title = 'Query';
        this.breadcrumb = 'ask for query';
        break;
      case 'report-astro-list':
        this.button_icon = 'description';
        this.button_title = 'Report';
        this.breadcrumb = 'get report';
        break;
      default:
        this.button_icon = 'phone_in_talk';
        this.button_title = 'Call';
        this.breadcrumb = 'talk to astrologer';
        break;
    }
  }

  getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  getCallAstrologerList() {
    this.spinner.show();
    this.orderService
      .getAstrologerList(this.astroData, this.endpoint)
      .subscribe(
        (res: any) => {
          const total = res.totalobj / 10;
          var temp = Math.round(total);
          if (temp < total) {
            this.totalPages = temp + 1;
          } else {
            this.totalPages = temp;
          }
          this.dataList = res.data;
          this.spinner.hide();
          this.initPage++;
          if (res.data.length == res.totalobj)
            this.totalPages = 1
        },
        (err: any) => {
          //console.log(err);
          this.spinner.hide();
        }
      );
  }
  @ViewChild("headerOne") headerOne: any;
  goToRecharge() {
    if (this.userdata.isLogin()) this.router.navigateByUrl('/recharge-wallet');
    else { this.toast.error('Please login to recharge'); this.headerOne?.openFromAnotherComponent() }
  }
  checkOnlineOffLine(astro: any) {
    switch (this.endpoint) {
      case 'call-astro-list':
      case 'promo-call-astro-list':
        return astro.is_call_active;
      case 'chat-astro-list':
      case 'promo-chat-astro-list':
        return astro.is_chat_active;
      case 'query-astro-list':
        return astro.is_query_active;
      case 'report-astro-list':
        return astro.is_report_active;
      default:
        return astro.is_call_active;
    }
  }

  padTo2Digits(num:any) {
    return num.toString().padStart(2, '0');
  }

  convertSecondsToMinutes(seconds:any){
    var min = Math.floor(seconds / 60)
    var sec = seconds % 60
    return `${this.padTo2Digits(min)}:${this.padTo2Digits(sec)}`;
  }

  convertSecondstoTime(astro: any) {
    switch (this.endpoint) {
      case 'chat-astro-list': {
        if (astro.is_chat_active) {
          if (astro.is_current_call_going || astro.is_current_chat_going){
            if (astro.astro_current_wait_time <= 0){
              if (astro.original_astro_current_wait_time > 0){
                return `Waiting Time:${this.convertSecondsToMinutes(astro.original_astro_current_wait_time)} Mins`;
              } else{
                return ''
              }
            }
            else if (astro.astro_current_wait_time > 0){
              return `Waiting Time:${this.convertSecondsToMinutes(astro.astro_current_wait_time)} Mins`;
            }
            else{
              return ''
            }
          }
          else{
            return ''
          }
        } else {
          if (
            astro.chat_online_time != null &&
            typeof astro.chat_online_time != 'undefined'
          ) {
            return `Online Time: ${this.datePipe.transform(
              astro.chat_online_time,
              'M/d/yy, h:mm a'
            )}`;
          } else {
            return ''
          }
        }
      }
      case 'call-astro-list': {
        if (astro.is_call_active) {

          if (astro.is_current_call_going || astro.is_current_chat_going){
            if (astro.astro_current_wait_time <= 0){
              if (astro.original_astro_current_wait_time > 0){
                return `Waiting Time:${this.convertSecondsToMinutes(astro.original_astro_current_wait_time)} Mins`;
              } else{
                return ''
              }
            }
            else if (astro.astro_current_wait_time > 0){
              return `Waiting Time:${this.convertSecondsToMinutes(astro.astro_current_wait_time)} Mins`;
            }
            else{
              return ''
            }
          }
          else{
            return ''
          }
          
        } else {
          if (
            astro.call_online_time != null &&
            typeof astro.call_online_time != 'undefined'
          ) {
            return `Online Time: ${this.datePipe.transform(
              astro.call_online_time,
              'M/d/yy, h:mm a'
            )}`;
            }else{
              return ''
            }
        }
      }
      default: {
        // if (
        //   !this.checkTimeCondition(
        //     astro.original_astro_current_wait_time,
        //     astro.astro_current_wait_time
        //   )
        // )
        //   return '';
        // if (astro.is_call_active) {
        //   return `Waiting Time: ${this.datePipe.transform(
        //     astro.original_astro_current_wait_time * 1000,
        //     'mm:ss'
        //   )} Mins`;
        // } else {
        //   if (
        //     astro.call_online_time != null &&
        //     typeof astro.call_online_time != 'undefined'
        //   ) {
        //     return `Online Time: ${this.datePipe.transform(
        //       astro.call_online_time,
        //       'M/d/yy, h:mm a'
        //     )}`;
        //   } else {
        //     return `Waiting Time: ${this.datePipe.transform(
        //       astro.original_astro_current_wait_time * 1000,
        //       'mm:ss'
        //     )} Mins`;
        //   }
        // }
        if (astro.is_call_active) {

          if (astro.is_current_call_going || astro.is_current_chat_going){
            if (astro.astro_current_wait_time <= 0){
              if (astro.original_astro_current_wait_time > 0){
                return `Waiting Time:${this.convertSecondsToMinutes(astro.original_astro_current_wait_time)} Mins`;
              } else{
                return ''
              }
            }
            else if (astro.astro_current_wait_time > 0){
              return `Waiting Time:${this.convertSecondsToMinutes(astro.astro_current_wait_time)} Mins`;
            }
            else{
              return ''
            }
          }
          else{
            return ''
          }
        } else {
          if (
            astro.call_online_time != null &&
            typeof astro.call_online_time != 'undefined'
          ) {
            return `Online Time: ${this.datePipe.transform(
              astro.call_online_time,
              'M/d/yy, h:mm a'
            )}`;
            }else{
              return ''
            }
        }
      }
    }
  }
  checkTimeCondition(originalTime: number, waitTime: number) {
    if (waitTime > 0) return waitTime > 0;
    else {
      if (originalTime > 0) return originalTime > 0;
      else return false;
    }
  }
  loadMore() {
    if (this.totalPages < this.initPage) {
      this.getCallAstrologerList();
    }
  }
  /** Notify Astrologer */
  notifyAstrologer(astroId: any) {
    if (this.userdata.isLogin())
      this.orderService
        .notifyAstroOrder({
          astroId: astroId,
          customerId: this.userdata.getId(),
          is_call: true,
        })
        .subscribe(
          (res: any) => {
            this.toast.success(res.message, 'Success');
          },
          (err: any) => {
            this.toast.error(err.error.message, 'Alert');
          }
        );
    else this.toast.error('Please login first!');
  }

  /**
   * Search Astrologer
   */
  name = '';
  astroName(event: any) {
    this.name = event.target.value;
    if (
      typeof this.name == 'undefined' ||
      this.name == '<empty string>' ||
      this.name.length == 0
    ) {
      this.astroData.name = '';
      this.getCallAstrologerList();
    }
  }
  searchAstroClick() {
    if (this.name.length >= 3) {
      this.searchAstrologer(this.name);
    } else {
      this.toast.error('Type at-least three alphabets');
    }
  }
  searchAstrologer(name: string) {
    this.astroData.name = name;
    this.getCallAstrologerList();
  }

  /**
   * Apply Filter
   */

  applyFilter(key: any, val: any, filterType: number) {
    this.filterType = filterType;
    this.spinner.show();
    switch (key) {
      case 'call_charges':
        this.astroData.call_charges = val;
        this.astroData.experience = '';
        this.astroData.total_call_taken = '';
        break;
      case 'experience':
        this.astroData.experience = val;
        this.astroData.total_call_taken = '';
        this.astroData.call_charges = '';
        break;
      case 'total_call_taken':
        this.astroData.experience = '';
        this.astroData.total_call_taken = val;
        this.astroData.call_charges = '';
        break;
    }
    if (this.endpoint == 'query-astro-list') {
      if (key == 'call_charges') {
        this.astroData.call_charges = '';
        this.astroData.chat_charges = '';
        this.astroData.report_charges = '';
        this.astroData.query_charges = val;
      }
      if (key == 'total_call_taken') {
        this.astroData.total_call_taken = ''
        this.astroData.total_chat_taken = ''
        this.astroData.total_report_taken = ''
        this.astroData.total_query_taken = val
      }
    }
    if (this.endpoint == 'report-astro-list') {
      if (key == 'call_charges') {
        this.astroData.call_charges = '';
        this.astroData.chat_charges = '';
        this.astroData.report_charges = val;
        this.astroData.query_charges = '';
      }
      if (key == 'total_call_taken') {
        this.astroData.total_call_taken = ''
        this.astroData.total_chat_taken = ''
        this.astroData.total_report_taken = val
        this.astroData.total_query_taken = ''
      }
    }
    if (this.endpoint == ('chat-astro-list' || 'promo-chat-astro-list')) {
      if (key == 'call_charges') {
        this.astroData.call_charges = '';
        this.astroData.chat_charges = val;
        this.astroData.report_charges = '';
        this.astroData.query_charges = '';
      }
      if (key == 'total_call_taken') {
        this.astroData.total_call_taken = ''
        this.astroData.total_chat_taken = val
        this.astroData.total_report_taken = ''
        this.astroData.total_query_taken = ''
      }
    }
    if (this.endpoint == ('call-astro-list' || 'promo-call-astro-list')) {
      if (key == 'call_charges') {
        this.astroData.call_charges = val;
        this.astroData.chat_charges = '';
        this.astroData.report_charges = '';
        this.astroData.query_charges = '';
      }
      if (key == 'total_call_taken') {
        this.astroData.total_call_taken = val
        this.astroData.total_chat_taken = ''
        this.astroData.total_report_taken = ''
        this.astroData.total_query_taken = ''
      }
    }
    this.getCallAstrologerList();
  }
  msg = '/Min';
  price(key: any): number {
    switch (this.endpoint) {
      case 'call-astro-list':
      case 'promo-call-astro-list':
        this.msg = '/Min.';
        return key.call_charges;

      case 'chat-astro-list':
      case 'promo-chat-astro-list':
        this.msg = '/Min.';
        return key.chat_charges;

      case 'query-astro-list':
        this.msg = '/Query';
        return key.query_charges;

      case 'report-astro-list':
        this.msg = '/Report';
        return key.report_charges;

      default:
        this.msg = '/Min.';
        return key.call_charges;
    }
  }
  checkDisablePossibility(key: any): Boolean {
    switch (this.endpoint) {
      case 'call-astro-list': return false;
      case 'promo-call-astro-list': return false;
      case 'chat-astro-list': return false;
      case 'promo-chat-astro-list':
        return false;
      case 'query-astro-list':
        return !this.checkOnlineOffLine(key);
      case 'report-astro-list':
        return !this.checkOnlineOffLine(key);
      default:
        return false;
    }
  }
  getPrice(key: any) {
    return `${this.price(key) * this.mul}${this.msg}`;
  }
  isOffer(key: any) {
    switch (this.endpoint) {
      case 'call-astro-list':
      case 'promo-call-astro-list':
        return key.isCallOfferApplicable;

      case 'chat-astro-list':
      case 'promo-chat-astro-list':
        return key.isChatOfferApplicable;

      case 'query-astro-list':
        return key.isQueryOfferApplicable;

      case 'report-astro-list':
        return key.isReportOfferApplicable;

      default:
        return key.isCallOfferApplicable;
    }
  }
  getOffer(key: any) {
    switch (this.endpoint) {
      case 'call-astro-list':
      case 'promo-call-astro-list':
        return `${key.astroCallChargesWithOffer}/Min.`;

      case 'chat-astro-list':
      case 'promo-chat-astro-list':
        return `${key.astroChatChargesWithOffer}/Min.`;

      case 'query-astro-list':
        return `${key.astroQueryChargesWithOffer}/Query`;

      case 'report-astro-list':
        return `${key.astroReportChargesWithOffer}/Report`;

      default:
        return `${key.astroCallChargesWithOffer}/Min.`;
    }
  }
  astroConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: 3,
    direction: 'horizontal',
    spaceBetween: 10,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.nav-left',
      prevEl: '.nav-right',
    },
    effect: 'slide',
  };
  astromConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: 'auto',
    direction: 'horizontal',
    spaceBetween: 5,
    autoplay: {
      delay: 5000,
    },
    effect: 'slide',
  };
  astroKey: any;
  buttonAction(key: any) {
    this.astroKey = key;
    if (!this.userdata.isLogin()) {
      sessionStorage.setItem('call', key._id);
      // sessionStorage.setItem('call', key.displayname);
      this.toast.error('Please login first');
      this.headerOne?.openFromAnotherComponent()
      return;
    } else if (this.userdata.getEmailVerify() == ('false' || false)) {
      sessionStorage.setItem('call', key._id);
      this.toast.error('Please Verify Email');
      this.router.navigateByUrl('/verify-email-otp')
      return
    } else if (!this.checkBalance()) {
      Swal.fire({
        title: 'Insufficient Balance',
        text: 'Minimum Amount required in wallet is ' + this.myProfile?.currencySymbol + ' ' + this.astroCharges,
        iconHtml: '<i class="fn-4 fa-solid fa-wallet text-primary border-0"></i>',
        confirmButtonText: 'Recharge Wallet',
        confirmButtonColor: '#19af0f'
      }).then((result: any) => {
        if (result.isConfirmed) {
          sessionStorage.setItem('call', key._id);
          this.router.navigateByUrl('/recharge-wallet')
        }
      })
      return
    } else {
      this.placeOrder();
    }
  }
  isOfferApplicable = false;
  offerId = '';
  placeOrder() {
    this.spinner.hide();
    this.router.navigateByUrl('/order-list-person');
    this.dataService.startOrder({
      astroId: this.astroKey._id,
      endpoint: this.endpoint,
    });
  }

  /**Wallet currencycode */
  myProfile: any;
  getMyProfile() {
    this.loginService.myProfile({ _id: this.userdata.getCustomerId() }).subscribe(
      (res: any) => {
        this.userdata.setProfileData(res.data)
        this.myProfile = res.data;
        this.userdata.setCurrentWallet(this.myProfile.current_wallet)
        this.walletBalance = Number(this.userdata.getCurrentWallet()) ?? 0;
      },
      (err) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }


  getCharges() {
    const key = this.astroKey;
    //console.log(this.astroKey)
    switch (this.endpoint) {
      case 'call-astro-list':
      case 'promo-astro-list-call': {
        var charges = 0;
        if (key.isCallOfferApplicable) {
          charges = key.astroCallChargesWithOffer;
        } else {
          charges = key.call_charges * this.mul;
        }
        return charges * 5;
      }

      case 'chat-astro-list':
      case 'promo-astro-list-chat': {
        var charges = 0;
        if (key.isChatOfferApplicable) {
          charges = key.astroChatChargesWithOffer;
        } else {
          charges = key.chat_charges * this.mul;
        }
        return charges * 5;
      }

      case 'query-astro-list': {
        var charges = 0;
        if (key.isQueryOfferApplicable) {
          charges = key.astroQueryChargesWithOffer;
        } else {
          charges = key.query_charges * this.mul;
        }
        return charges;
      }

      case 'report-astro-list': {
        var charges = 0;
        if (key.isReportOfferApplicable) {
          charges = key.astroReportChargesWithOffer;
        } else {
          charges = key.report_charges * this.mul;
        }
        return charges;
      }

      default: {
        var charges = 0;
        if (key.isCallOfferApplicable) {
          charges = key.astroCallChargesWithOffer;
        } else {
          charges = key.call_charges * this.mul;
        }
        return charges * 5;
      }
    }
  }

  astroCharges: number = 0
  checkBalance() {
    //this.spinner.show()
    const balance = this.myProfile.current_wallet;
    this.astroCharges = this.getCharges();
    // console.log(astroCharges, balance)
    //return
    if (this.astroCharges > balance) {
      return false
    } else {
      return true
    }
  }
}
