import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/authguard';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AstroMallComponent } from './components/astro-mall/astro-mall.component';
import { AstroSubcategoryProductComponent } from './components/astro-mall/astro-subcategory-product/astro-subcategory-product.component';
import { CartDetailsComponent } from './components/astro-order-cart/cart-details/cart-details.component';
import { CartProductDetailsComponent } from './components/astro-order-cart/cart-product-details/cart-product-details.component';
import { AstrologerDetailsComponent } from './components/astrologer-details/astrologer-details.component';
import { BlogDetailsComponent } from './components/blog/blog-details/blog-details.component';
import { BlogListComponent } from './components/blog/blog-list/blog-list.component';
import { ShowBlogListComponent } from './components/blog/show-blog-list/show-blog-list.component';
import { AstrologerComponent } from './components/booking/astrologer/astrologer.component';
import { BookingComponent } from './components/booking/booking.component';
import { CheckoutComponent } from './components/booking/checkout/checkout.component';
import { CallAstrologerListComponent } from './components/call-astrologer-list/call-astrologer-list.component';
import { CategoryproductComponent } from './components/categoryproduct/categoryproduct.component';
import { ChatPlacedComponent } from './components/chat-placed/chat-placed.component';
import { ChatWalletRechargeComponent } from './components/chat-wallet-recharge/chat-wallet-recharge.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { CustomerDashboardComponent } from './components/customer-dashboard/customer-dashboard.component';
import { CustomerSupportComponent } from './components/customer-support/customer-support.component';
import { HoroscopeComponent } from './components/daily-horoscope/horoscope/horoscope.component';
import { ZodiacComponent } from './components/daily-horoscope/zodiac/zodiac.component';
import { DynamicPageComponent } from './components/dynamic-page/dynamic-page.component';
import { FaqComponent } from './components/faq/faq.component';
import { FestivalDetailsComponent } from './components/festival-details/festival-details.component';
import { FestivalsListComponent } from './components/festivals-list/festivals-list.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { GeneralPredictionComponent } from './components/general-prediction/general-prediction.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { AddWalletIssueComponent } from './components/issue/add-wallet-issue/add-wallet-issue.component';
import { IssueDetailComponent } from './components/issue/issue-detail/issue-detail.component';
import { IssueListComponent } from './components/issue/issue-list/issue-list.component';
import { KundliShowComponent } from './components/kundli/kundli-show/kundli-show.component';
import { KundliComponent } from './components/kundli/kundli.component';
import { LayoutComponent } from './components/layout/layout.component';
import { TestLayoutComponent } from './components/layout/test-layout/test-layout.component';
import { LoginFullpageComponent } from './components/login-fullpage/login-fullpage.component';
import { LoginUpdateComponent } from './components/login-update/login-update.component';
import { MatchMakingComponent } from './components/match-making/match-making.component';
import { MessageCeoComponent } from './components/message-ceo/message-ceo.component';
import { MyAddressComponent } from './components/my-address/my-address.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OngoingChatCustomerComponent } from './components/ongoing-chat-customer/ongoing-chat-customer.component';
import { OngoingGroupchatCustomerComponent } from './components/ongoing-groupchat-customer/ongoing-groupchat-customer.component';
import { OrderAddPersonComponent } from './components/order-add-person/order-add-person.component';
import { CallDetailsComponent } from './components/orders/call-details/call-details.component';
import { CallOrderListComponent } from './components/orders/call-order-list/call-order-list.component';
import { CallPlacedComponent } from './components/orders/call-placed/call-placed.component';
import { ChatDetailsComponent } from './components/orders/chat-details/chat-details.component';
import { ChatOrderListPersonComponent } from './components/orders/chat-order-list-person/chat-order-list-person.component';
import { ChatOrderListComponent } from './components/orders/chat-order-list/chat-order-list.component';
import { QueryDetailsComponent } from './components/orders/query-details/query-details.component';
import { QueryOrderListPersonComponent } from './components/orders/query-order-list-person/query-order-list-person.component';
import { QueryOrderListComponent } from './components/orders/query-order-list/query-order-list.component';
import { ReportDetailsComponent } from './components/orders/report-details/report-details.component';
import { ReportOrderListPersonComponent } from './components/orders/report-order-list-person/report-order-list-person.component';
import { ReportOrderListComponent } from './components/orders/report-order-list/report-order-list.component';
import { StoreOrderDetailComponent } from './components/orders/store-order-detail/store-order-detail.component';
import { StoreOrderListComponent } from './components/orders/store-order-list/store-order-list.component';
import { WaitingListComponent } from './components/orders/waiting-list/waiting-list.component';
import { PanchangComponent } from './components/panchang/panchang.component';
import { AddPersonComponent } from './components/person/add-person/add-person.component';
import { SinglePersonComponent } from './components/person/single-person/single-person.component';
import { PolicyPageComponent } from './components/policy-page/policy-page.component';
import { RegisterComponent } from './components/register/register.component';
import { ListAllRemedyComponent } from './components/remedy/list-all-remedy/list-all-remedy.component';
import { ShareChatComponent } from './components/share-chat/share-chat.component';
import { ShowPromoAdminListComponent } from './components/show-promo-admin-list/show-promo-admin-list.component';
import { ShowReportListComponent } from './components/show-report-list/show-report-list.component';
import { SingleproductComponent } from './components/singleproduct/singleproduct.component';
import { SubcategoryProductComponent } from './components/subcategory-product/subcategory-product.component';
import { DetailSupportComponent } from './components/support/detail-support/detail-support.component';
import { VerifyEmailOtpComponent } from './components/verify-email-otp/verify-email-otp.component';
import { VerifyPersonPhoneOtpComponent } from './components/verify-person-phone-otp/verify-person-phone-otp.component';
import { VerifyPhoneOtpComponent } from './components/verify-phone-otp/verify-phone-otp.component';
import { WalletRechargeComponent } from './components/wallet/wallet-recharge/wallet-recharge.component';
import { WalletTransactionComponent } from './components/wallet/wallet-transaction/wallet-transaction.component';
import { BlogCategoriesComponent } from './components/blog-categories/blog-categories.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'user', component: LayoutComponent, children: [
      { path: 'test', component: TestLayoutComponent }
    ]
  },
  { path: 'home', component: HomepageComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'about-us', component: AboutUsComponent },
  // Policy Pages //
  { path: 'privacy-policy', component: PolicyPageComponent },
  // { path: 'pricing-policy', component: PolicyPageComponent },
  { path: 'terms-conditions', component: PolicyPageComponent },
  { path: 'disclaimer', component: PolicyPageComponent },
  { path: 'return-policy', component: PolicyPageComponent },
  { path: 'refund-policy', component: PolicyPageComponent },
  //  Policy Pages //
  //{ path: 'faq', component: FaqComponent },
  { path: 'contact-us', component: ContactPageComponent },
  { path: 'message-ceo', component: MessageCeoComponent },
  { path: 'login', component: LoginFullpageComponent },
  { path: 'register', component: RegisterComponent },

  /** Astro List pages */
  { path: 'promo-astro-list-call', component: CallAstrologerListComponent },
  { path: 'promo-astro-list-chat', component: CallAstrologerListComponent },
  { path: 'promo-call-astro-list', component: CallAstrologerListComponent },
  { path: 'promo-chat-astro-list', component: CallAstrologerListComponent },
  { path: 'call-astro-list', component: CallAstrologerListComponent },
  { path: 'chat-astro-list', component: CallAstrologerListComponent },
  { path: 'query-astro-list', component: CallAstrologerListComponent },
  { path: 'report-astro-list', component: CallAstrologerListComponent },

  { path: 'verify-phone-otp/:id', component: VerifyPhoneOtpComponent },
  { path: 'forget-pass', component: ForgetPasswordComponent },
  /**
   * Address
   */
  { path: 'my-address', component: MyAddressComponent, canActivate: [AuthGuard], },

  /**
   * Notifications
   */
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard],
  },
  /**
   * Support
   */
  {
    path: 'support/:id',
    component: DetailSupportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-support',
    component: CustomerSupportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'wallet',
    component: WalletTransactionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recharge-wallet',
    component: WalletRechargeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'query-order-list',
    component: QueryOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'query-order-list/:id',
    component: QueryDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'verify-email-otp',
    component: VerifyEmailOtpComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order-list-person',
    component: OrderAddPersonComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'show-report',
    component: ShowReportListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'report-order-list',
    component: ReportOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'report-order-list/:id',
    component: ReportDetailsComponent,
    canActivate: [AuthGuard],
  },

  /**
   * Person
   */
  {
    path: 'my-profile/:id',
    component: SinglePersonComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'add-person',
    component: AddPersonComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-person/:id',
    component: AddPersonComponent,
    canActivate: [AuthGuard],
  },
  /**
   * Call Order
   */
  {
    path: 'call-placed',
    component: CallPlacedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'wait-list',
    component: WaitingListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'verify-call-otp/:id',
    component: VerifyPersonPhoneOtpComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'call-order-list',
    component: CallOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'call-order-list/:id',
    component: CallDetailsComponent,
    canActivate: [AuthGuard],
  },
  /**
   * Issue
   */
  {
    path: 'add-wallet-issue/:id',
    component: AddWalletIssueComponent,
    canActivate: [AuthGuard],
  },
  { path: 'issue', component: IssueListComponent, canActivate: [AuthGuard] },
  {
    path: 'issue/:id',
    component: IssueDetailComponent,
    canActivate: [AuthGuard],
  },
  /**
   * Daily Horoscope
   */
  { path: 'daily-horoscope', component: ZodiacComponent },
  { path: 'daily-horoscope/:id', component: HoroscopeComponent },

  /**
   * Chat Orders
   */
  {
    path: 'chat-order-list',
    component: ChatOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chat-order-list/:id',
    component: ChatDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chat-placed',
    component: ChatPlacedComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'chat-ongoing/:id',
  //   component: OngoingChatCustomerComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'recharge-chat-amount/:id',
    component: ChatWalletRechargeComponent,
    canActivate: [AuthGuard],
  },

  /**
   * Astrologer Detail
   */
  { path: 'astrologer/:id', component: AstrologerDetailsComponent },

  /**
   * Notification
   */
  {
    path: 'notification',
    component: NotificationComponent,
    canActivate: [AuthGuard],
  },

  /**
   * Admin Promo List
   */
  {
    path: 'show-promo',
    component: ShowPromoAdminListComponent,
    canActivate: [AuthGuard],
  },

  /**
   * Kundli
   */
  { path: 'kundli', component: KundliComponent },

  /**
   * Astro Mall
   */
  { path: 'astro-shop', component: AstroMallComponent }, // get Category
  { path: 'astro-shop/:id', component: AstroSubcategoryProductComponent }, // get subcategory -- New 07 may 2022
  // { path: 'products/:id', component: AstroSubcategoryMallComponent }, // get Subcategory by category
  { path: 'product/:type/:id', component: CategoryproductComponent }, // get product by Category
  { path: 'astro-product/:id', component: SubcategoryProductComponent }, // get product by Subcategory
  { path: 'product-detail/:_id', component: SingleproductComponent }, // get product detail


  /**
   * Booking
   */
  {
    path: 'booking-astrologer/:_id',
    component: AstrologerComponent,
    canActivate: [AuthGuard],
  }, //product Id
  {
    path: 'booking/:_id',
    component: BookingComponent,
    canActivate: [AuthGuard],
  }, //product Id

  /**
   * Checkout
   */
  {
    path: 'checkout/:_id',
    component: CheckoutComponent,
    canActivate: [AuthGuard],
  }, //order ID

  // --------------------------------------------------

  /** place query order and list order person */
  {
    path: 'query-order-list-person',
    component: QueryOrderListPersonComponent,
    canActivate: [AuthGuard],
  },

  /** place report order and list order person */
  {
    path: 'report-order-list-person',
    component: ReportOrderListPersonComponent,
    canActivate: [AuthGuard],
  },

  /** place chat order and list order person */
  {
    path: 'chat-order-list-person',
    component: ChatOrderListPersonComponent,
    canActivate: [AuthGuard],
  },

  /** Show Store Orders */
  {
    path: 'store-order-list',
    component: StoreOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-order-list/:id',
    component: StoreOrderDetailComponent,
    canActivate: [AuthGuard],
  },

  /** Group chat */
  {
    path: 'order-chat/:id',
    component: OngoingGroupchatCustomerComponent,
    canActivate: [AuthGuard],
  },

  /** Blogs */
  { path: 'blog', component: ShowBlogListComponent },
  { path: 'blog/:id', component: BlogDetailsComponent },
  { path: 'blog-list', component: BlogListComponent },
  { path: 'blog-categories/:name/:id', component: BlogCategoriesComponent },

  /** Festivals */
  {
    path: 'festivals/:id',
    component: FestivalDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'festivals',
    component: FestivalsListComponent,
    canActivate: [AuthGuard],
  },

  /** Cart functionality */
  {
    path: 'order-product/:id', component: CartProductDetailsComponent, canActivate: [AuthGuard],
  },
  {
    path: 'view-cart', component: CartDetailsComponent, canActivate: [AuthGuard],
  },
  {
    path: 'list-remedy/:id', component: ListAllRemedyComponent, canActivate: [AuthGuard],
  },
  {
    path: 'dashboard', component: CustomerDashboardComponent, canActivate: [AuthGuard],
  },
  {
    path: 'kundli-view', component: KundliShowComponent
  },
  {
    path: 'match-making', component: MatchMakingComponent
  },
  {
    path: 'panchang', component: PanchangComponent
  },
  {
    path: 'prediction', component: GeneralPredictionComponent
  },
  /**Open Link to share chat */
  {
    path: 'share/:id', component: ShareChatComponent
  },
  {
    path: 'page/:slug', component: DynamicPageComponent
  },
  { path: '**', component: ComingSoonComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
