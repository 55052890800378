<section class="d-none d-lg-block thick-border" (scroll)="onWindowScroll($event)">
  <div class="d-flex flex-column">
    <div class="row bg-secondary">
      <div class="container">
        <ul class="header-nav py-2 header-icon d-flex align-items-center">
          <li class="nav-item">
            <div id="google_translate_element"></div>

            <!-- <select
              class="
                border-0 
                bg-transparent
                text-white
                border-end
                mr-1
                cursor-pointer
              "
            >
              <option
                class="inactive-text"
                *ngFor="let lang of language"
                value="{{ lang.id }}"
              >
                {{ lang.name }}
              </option>
            </select> -->
          </li>
          <!-- <li class="nav-item">
            <select
              class="border-0 bg-transparent text-white ml-1 cursor-pointer"
            >
              <option
                class="inactive-text"
                *ngFor="let cur of currency"
                value="{{ cur.id }}"
              >
                {{ cur.name }}
              </option>
            </select>
          </li> -->
          <li class="nav-item mx-2">
            <a class="nav-link text-white p-0 d-flex" href="mailto: info@astroriver.com">
              <!-- <i class="fi fi-email secondary-text fn-3"></i>&nbsp;&nbsp;info@astroriver.com</a> -->
              <i class="fa-regular fa-envelope secondary-text fn-3 align-middle"></i>&nbsp;&nbsp;info@astroriver.com
            </a>

          </li>
          <li class=" nav-item">
            <i class="fa fa-phone-alt rounded-circle border border-white"></i>
            <i class="fi fi-whatsapp text-white fn-4"></i>
          </li>
          <li class="nav-item">
            <a class="fn-1 text-white fw-500" href="tel:+917009127641">&nbsp; +91-70091-27641 </a><span
              class="yellow-text fn-1 fw-500">-24x7 Support</span>
          </li>
          <ul class="
              header-nav header-icon
              flex-grow-1
              d-flex
              justify-content-end
            ">
            <li class="nav-item">
              <a target="_blank" href="https://www.facebook.com/astroriverdotcom">
                <i class="fab rounded-circle border border-white fa-facebook-f"></i>
              </a>
            </li>
            <li class="nav-item">
              <a target="_blank" href="https://www.twitter.com/astroriver">
                <i class="fab rounded-circle border border-white fa-twitter"></i>
              </a>
            </li>
            <li class="nav-item">
              <a target="_blank" href="https://www.instagram.com/astroriverofficial/">
                <i class="fab rounded-circle border border-white fa-instagram"></i>
              </a>
            </li>
            <li class="nav-item">
              <a target="_blank" href="https://www.pinterest.com/astroriver">
                <i class="fab rounded-circle border border-white fa-pinterest-p"></i>
              </a>
            </li>
            <li class="nav-item">
              <a target="_blank" href="https://www.linkedin.com/company/astro-river/">
                <i class="fab rounded-circle border border-white fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </ul>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="container row header-main">
        <div class="col-md-3 pl-md-2 position-relative">
          <a routerLink="/home">
            <img style="z-index: 1; right: 32%; top:8px" class="w-50 position-absolute "
              src="../../../assets/images/logo-w-border.png" />
          </a>
          <div class="logo-bc h-100 w-75 position-absolute"></div>
        </div>
        <div class="col-md-9 nav-header p-0">
          <div class="d-flex w-100">
            <ul class="flex-grow-1 d-flex d-inline d-flex d-inline pt-4 pb-3">
              <li>
                <a routerLink="/home" routerLinkActive="active-nav-link">Home</a>
              </li>
              <li class="position-relative">
                <span style="bottom: 75%; right: 0"
                  class="position-absolute right-0 bottom-50 badge bdg-txt-fw bg-success">Live</span>
                <a routerLink="/call-astro-list" routerLinkActive="active-nav-link">Talk</a>
              </li>
              <li>
                <a routerLink="/chat-astro-list" routerLinkActive="active-nav-link">Chat</a>
              </li>
              <li>
                <a routerLink="/query-astro-list" routerLinkActive="active-nav-link">Ask An Expert</a>
              </li>
              <li>
                <a routerLink="/report-astro-list" routerLinkActive="active-nav-link">2022 Reports</a>
              </li>
              <li>
                <a routerLink="/daily-horoscope" routerLinkActive="active-nav-link"> Horoscope</a>
              </li>
              <li>
                <a routerLink="/astro-shop" routerLinkActive="active-nav-link">AstroShop</a>
              </li>
              <li>
                <a routerLink="/blog-list" routerLinkActive="active-nav-link">Blogs</a>
              </li>
              <!-- <li *ngIf="isLogin">
                <a routerLink="/view-cart" routerLinkActive="active-nav-link"><i
                    class="fa-solid fa-cart-shopping"></i></a>
              </li> -->
            </ul>

            <a *ngIf="!isLogin" (click)="openModal(login)" class="
                text-white
                align-self-center
                m-0
                shadow-0
                btn
                bg-green
                px-3 
                py-2
                rounded
              ">
              <i class="far fa-user"></i> LOGIN
            </a>
            <mat-form-field appearance="fill" *ngIf="isLogin" class="align-items-center text-center">
              <mat-label class="w-100 header-drop text-white align-middle ">
                <!-- <i class="center-align material-icons-round ">
                  person_outline </i> -->
                Account
              </mat-label>
              <mat-select class="bg-green rounded account-select">
                <mat-option class="cus-border-bottom" value="option1"><a class="d-block" routerLink="/dashboard"><i
                      class="material-icons-outlined bottom-align"> home </i>&nbsp;Dashboard</a></mat-option>
                <mat-option class="cus-border-bottom" value="option1"><a class="d-block" routerLink="/view-cart"><i
                      class="material-icons-outlined bottom-align"> shopping_cart </i>&nbsp;Cart</a></mat-option>
                <mat-option class="cus-border-bottom" value="option1"><a class="d-block" routerLink="/my-profile"><i
                      class="material-icons-outlined bottom-align"> person </i>&nbsp;Profile</a></mat-option>
                <mat-option class="cus-border-bottom" value="option2">
                  <a class="d-block" routerLink="/call-order-list"><i class="material-icons-outlined bottom-align">
                      phone </i>&nbsp;Call
                    History</a>
                </mat-option>
                <mat-option class="cus-border-bottom" value="option3"><a class="d-block"
                    routerLink="/chat-order-list"><i class="material-icons-outlined bottom-align"> chat </i>&nbsp;Chat
                    History
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option3"><a class="d-block"
                    routerLink="/report-order-list"><i class="material-icons-outlined bottom-align">
                      description </i>&nbsp;Report History
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option3"><a class="d-block"
                    routerLink="/query-order-list"><i class="material-icons-outlined bottom-align">
                      help_outline </i>&nbsp;Query History
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option4"><a class="d-block" routerLink="/wait-list"><i
                      class="material-icons-outlined bottom-align">
                      hourglass_bottom </i>&nbsp;Waiting List
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option4"><a class="d-block" routerLink="/wallet"><i
                      class="material-icons-outlined bottom-align">
                      account_balance_wallet </i>&nbsp;Wallet
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option4"><a class="d-block"
                    routerLink="/store-order-list"><i class="material-icons-outlined bottom-align">
                      shopping_bag</i>&nbsp;My Orders
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option4"><a class="d-block" routerLink="/show-promo"><i
                      class="fn-3 fi fi-mobile-alt bottom-align"> </i>&nbsp;Promo
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option5"><a class="d-block"
                    routerLink="/customer-support"><i class="material-icons-round"> support_agent </i>
                    &nbsp;Customer Support
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option6"><a class="d-block" routerLink="/issue"><i
                      class="material-icons-round bottom-align">
                      history_edu
                    </i>
                    &nbsp;Issue History
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option6"><a class="d-block" routerLink="/my-address"><i
                      class="material-icons-round bottom-align"> home </i>
                    &nbsp;My Address
                  </a></mat-option>
                <mat-option class="cus-border-bottom" value="option6"><a class="d-block" routerLink="/festivals"><i
                      class="material-icons-round bottom-align"> festival </i>
                    &nbsp;Festivals
                  </a></mat-option>
                <!-- <mat-option class="cus-border-bottom" value="option6"><a routerLink="/list-remedy"><i
                    class="material-icons-round bottom-align"> festival </i>
                  &nbsp;Remedy List
                </a></mat-option> -->
                <!--     <mat-option class="cus-border-bottom" value="option6"
                  ><a routerLink="/notifications"
                    ><i class="material-icons-round bottom-align">
                      notifications
                    </i>
                    &nbsp;Notifications
                  </a></mat-option
                > -->

                <mat-option value="option5"><a (click)="logout()"><i class="material-icons-outlined bottom-align">
                      logout </i>&nbsp;Logout
                  </a></mat-option>
              </mat-select>
            </mat-form-field>
            <div class="d-flex align-items-center" *ngIf="isLogin">
              <a [routerLink]="['/view-cart']" routerLinkActive="active-nav-link" class="pt-3 header-cart-icon"><i
                  class="fn-4 fa-solid fa-cart-shopping text-primary"></i></a>
            </div>

            <!-- <a
              *ngIf="isLogin"
              class="btn bg-green text-white px-3 py-2 dropdown-toggle"
              role="button"
              id="dropdownMenuLink"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="material-icons-round"> person </i>&nbsp;&nbsp;Account
            </a>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li>
                <a
                  (click)="logout()"
                  class="
                    align-self-center
                    m-0
                    fn-0
                    rounded
                    shadow-0
                    btn
                    bg-green
                    px-3
                    py-2
                  "
                >
                  <i class="far fa-user"></i> LOGOUT
                </a>
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-body text-center bg-secondary-2 border border-dark" *ngIf="isGoingOn">
    <marquee>{{ message }}</marquee>
  </div>
</section>
<!-- Mobile TopBar Start -->
<section class="d-lg-none d-flex px-4 py-2">
  <ul class="d-inline-flex align-items-cente">
    <li class="align-items-center d-flex">
      <a (click)="toggleButton()" class="align-middle fn-4"><span class="material-icons">menu</span></a>
    </li>
    <li>
      <img class="ml-3 align-middle" src="../../../assets/images/new_logo.png" style="width: 5.6rem" />
    </li>
  </ul>

  <ul class="flex-grow-1 d-inline-flex justify-content-end align-items-center line-hide">
    <li>
      <a (click)="openModal(login)" *ngIf="!isLogin" class="
          rounded
          text-body
          shadow-0
          btn
          px-3
          py-1
          text-uppercase
          bg-secondary-2
        ">
        <i class="fas fa-user"></i>&nbsp;&nbsp;Login
      </a>
      <a (click)="logout()" *ngIf="isLogin" class="
          rounded
          text-body
          shadow-0
          btn
          px-3
          py-1
          text-uppercase
          bg-secondary-2
        ">
        <i class="fas fa-user"></i>&nbsp;&nbsp;Logout
      </a>
    </li>
    <li class="mr-1 line"><span class="cus-divider"></span></li>
    <li class="d-none">
      <a>
        <i class="material-icons-outlined text-body"> shopping_basket </i>
        <span class="cart-count badge rounded-pill bdg-txt-fw badge-notification bg-warning">1</span>
      </a>
    </li>
  </ul>
</section>
<!-- Mobile TopBar end -->

<!-- Bottom bar Start -->
<section class="d-lg-none fixed-bottom bg-active px-2 py-2 bottom-bar">
  <div class="d-flex justify-content-around">
    <div class="text-center">
      <a routerLink="/call-astro-list" class="align-middle"><span
          class="material-icons">phone_in_talk</span><br /><span>Call</span></a>
    </div>
    <div class="text-center">
      <a routerLink="/query-astro-list" class="align-middle"><span
          class="material-icons">help_outline</span><br /><span>Query</span></a>
    </div>
    <div class="text-center position-relative">
      <div class="bg-secondary shopping-icon position-absolute">
        <a routerLink="/astro-shop" class="align-middle text-white"><span class="material-icons">shopping_bag</span></a>
      </div>
    </div>
    <div class="text-center">
      <a routerLink="/report-astro-list" class="align-middle"><span
          class="material-icons">description</span><br /><span>Report</span></a>
    </div>
    <div class="text-center">
      <a routerLink="/chat-astro-list" class="align-middle"><span
          class="material-icons">chat</span><br /><span>Chat</span></a>
    </div>
  </div>
</section>
<!-- Bottom bar End -->
<ng-template #login let-modal class="loginModal">
  <a (click)="modal.dismiss('Cross click')" class="d-lg-none d-md-none modal-close-sm"><i
      class="fi fi-close-a text-white"></i></a>
  <form>
    <app-login-update></app-login-update>
  </form>
</ng-template>

<!-- SideBar Start -->
<div *ngIf="toggleView" [@enterAnimation] class="d-lg-none m-sidebar position-absolute">
  <div class="h-100 inner">
    <div class="w-100 d-flex main-img p-3 position-relative">
      <a (click)="toggleButton()" class="position-absolute end-0 top-0 m-2 text-white"><i class="fa fa-times"></i></a>
      <img class="rounded-circle size-50" src="../assets/images/astro-logo.png" />
      <div class="flex-grow-1 d-flex flex-column p-2">
        <h4 class="fw-500 text-white text-capitalize">{{ userName }}</h4>
        <h6 class="fw-500 text-white">{{ walletBalance | currency: "INR" }}</h6>
      </div>
    </div>
    <ul class="p-3">
      <li>
        <a routerLink="/home" (click)="toggleButton()" routerLinkActive="active-nav-link"><i
            class="material-icons-round rounded-top"> home </i><span class="nav-bdr">Home</span></a>
      </li>
      <li>
        <a *ngIf="isLogin" routerLink="/dashboard" (click)="toggleButton()" routerLinkActive="active-nav-link"><i
            class="material-icons-round rounded-top"> dashboard </i><span class="nav-bdr">Dashboard</span></a>
      </li>
      <li>
        <a (click)="toggleButton()" routerLink="/call-astro-list" routerLinkActive="active-nav-link"><i
            class="material-icons-round"> phone_in_talk </i><span class="nav-bdr">Talk</span></a>
      </li>
      <li>
        <a (click)="toggleButton()" routerLink="/chat-astro-list" routerLinkActive="active-nav-link"><i
            class="material-icons-round"> chat </i><span class="nav-bdr">Chat</span></a>
      </li>
      <li>
        <a (click)="toggleButton()" routerLink="/query-astro-list" routerLinkActive="active-nav-link"><i
            class="material-icons-round"> help </i><span class="nav-bdr">Ask An Expert</span></a>
      </li>
      <li>
        <a (click)="toggleButton()" routerLink="/report-astro-list" routerLinkActive="active-nav-link"><i
            class="material-icons-round"> description </i><span class="nav-bdr">2022 Reports</span></a>
      </li>
      <li>
        <a (click)="toggleButton()" routerLink="/daily-horoscope" routerLinkActive="active-nav-link"><i
            class="material-icons-round"> phone_in_talk </i><span class="nav-bdr">Horoscope</span></a>
      </li>
      <li>
        <a (click)="toggleButton()" routerLink="/astro-shop" routerLinkActive="active-nav-link"><i
            class="material-icons-round"> store </i><span class="nav-bdr">AstroShop</span></a>
      </li>
      <li>
        <a (click)="toggleButton()" routerLink="/blog-list" routerLinkActive="active-nav-link"><i
            class="material-icons-round"> article </i><span class="nav-bdr">Blogs</span></a>
      </li>
      <!-- <li>
        <a *ngIf="!isLogin" (click)="toggleButton()" routerLink="/daily-horoscope" routerLinkActive="active-nav-link"><i
            class="material-icons-round rounded-bottom"> person </i><span class="nav-bdr">Login</span></a>
        <a *ngIf="isLogin" (click)="toggleButton()" routerLink="/daily-horoscope" routerLinkActive="active-nav-link"><i
            class="material-icons-round rounded-bottom"> person </i><span class="nav-bdr">Logout</span></a>
      </li> -->
    </ul>
  </div>
</div>
<!-- SideBar End -->
<!-- ChatBot Firebase Start-->
<div class="
    shadow
    position-absolute
    bg-green
    text-white
    chat-bot
    d-flex
    cb-w
    flex-column
    d-none d-lg-block d-md-block
  " [class.expanded]="isExpanded" *ngIf="isChatGoing && isLogin">
  <div class="px-4 py-2 chat-wind">
    <a class="pointer" (click)="toggleChatBot()">
      <div class="d-inline ch-icon"><i class="fi fi-messenger fn-3"></i></div>
      <div class="d-inline ch-text">Chat With Astrologer</div>
    </a>
  </div>
  <div class="h-100 bg-active d-flex">
    <app-ongoing-chat-customer ordeServiceId="ordeServiceId" class="w-100"></app-ongoing-chat-customer>
  </div>
</div>
<!-- ChatBot Firebase End-->

<!-- ChatBot Support Start-->
<div class="
    shadow
    position-absolute
    bg-green
    text-white
    chat-bot
    d-flex
    flex-column
    d-none d-lg-block d-md-block
  " [class.expanded]="isExpanded" *ngIf="isSupportGoing">
  <div class="px-4 py-2 text-center">
    <a class="pointer" (click)="toggleChatBot()">
      <i class="material-icons-round fn-3 center-align"> support_agent </i>&nbsp;&nbsp;How can we help?</a>
    <a (click)="closeSupport()" class="text-white float-right"><i class="material-icons"> cancel </i>
    </a>
  </div>
  <div class="h-100 bg-active d-flex">
    <app-support-dialog supportId="supportId" class="w-100"></app-support-dialog>
  </div>
</div>
<!-- ChatBot Support End-->

<!-- Waiting List Floating Button -->
<div id="container-floating" class="position-absolute bottom-0 end-0" *ngIf="waitingList.length > 0">
  <div *ngFor="let waiting of waitingList; let i = index" class="nd{{ i + 1 }} nds" data-toggle="tooltip"
    data-placement="left" data-original-title="Edoardo@live.it">
    <a routerLink="/wait-list">
      <img class="reminder size-50 rounded-circle border" [src]="getSanitizeUrl(waiting.astroId.profile_image)" />
    </a>
    <p class="letter" (click)="gotToWaiting()">
      <span>{{ getAstroName(waiting.astroId) }}</span>
      <br />
      <span class="fn-0 text-body">{{
        convertSecondstoTime(waiting.last_wait_time)
        }}</span>
    </p>
  </div>

  <div (click)="gotToWaiting()" id="floating-button" data-toggle="tooltip" data-placement="left"
    data-original-title="Create">
    <p class="plus fn-0 text-body">Waiting</p>
  </div>
</div>
<!-- Waiting List Floating Button End -->