import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import {
  NgbModule,
  NgbCollapseModule,
  NgbModalModule,
} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CallAstrologerListComponent } from './components/call-astrologer-list/call-astrologer-list.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule, DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgOtpInputModule } from 'ng-otp-input';
import { VerifyPhoneOtpComponent } from './components/verify-phone-otp/verify-phone-otp.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { AddPersonComponent } from './components/person/add-person/add-person.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { WalletTransactionComponent } from './components/wallet/wallet-transaction/wallet-transaction.component';
import { WalletRechargeComponent } from './components/wallet/wallet-recharge/wallet-recharge.component';
import { SinglePersonComponent } from './components/person/single-person/single-person.component';
import { QueryOrderListComponent } from './components/orders/query-order-list/query-order-list.component';
import { QueryDetailsComponent } from './components/orders/query-details/query-details.component';
import { VerifyEmailOtpComponent } from './components/verify-email-otp/verify-email-otp.component';
import { OrderAddPersonComponent } from './components/order-add-person/order-add-person.component';
import { ShowReportListComponent } from './components/show-report-list/show-report-list.component';
import { ReportOrderListComponent } from './components/orders/report-order-list/report-order-list.component';
import { ReportDetailsComponent } from './components/orders/report-details/report-details.component';
import { CallPlacedComponent } from './components/orders/call-placed/call-placed.component';
import { WaitingListComponent } from './components/orders/waiting-list/waiting-list.component';
import { CallOrderListComponent } from './components/orders/call-order-list/call-order-list.component';
import { CallDetailsComponent } from './components/orders/call-details/call-details.component';
import { AddWalletIssueComponent } from './components/issue/add-wallet-issue/add-wallet-issue.component';
import { IssueDetailComponent } from './components/issue/issue-detail/issue-detail.component';
import { IssueListComponent } from './components/issue/issue-list/issue-list.component';
import { ZodiacComponent } from './components/daily-horoscope/zodiac/zodiac.component';
import { HoroscopeComponent } from './components/daily-horoscope/horoscope/horoscope.component';
import { ChatDetailsComponent } from './components/orders/chat-details/chat-details.component';
import { ChatOrderListComponent } from './components/orders/chat-order-list/chat-order-list.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { DetailSupportComponent } from './components/support/detail-support/detail-support.component';
import { AstrologerDetailsComponent } from './components/astrologer-details/astrologer-details.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ScrolltopService } from './service/scrolltop.service';
import { ChatPlacedComponent } from './components/chat-placed/chat-placed.component';
import { VerifyPersonPhoneOtpComponent } from './components/verify-person-phone-otp/verify-person-phone-otp.component';

import { MessagingService } from './service/messaging/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AsyncPipe } from '@angular/common';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { OngoingChatCustomerComponent } from './components/ongoing-chat-customer/ongoing-chat-customer.component';
import { ScrollToBottomDirective } from './components/ongoing-chat-customer/scroll-to-bottom.directive';
import { ChatWalletRechargeComponent } from './components/chat-wallet-recharge/chat-wallet-recharge.component';
import { ShowPromoAdminListComponent } from './components/show-promo-admin-list/show-promo-admin-list.component';
import { AuthInterceptor } from './auth/auth.interceptor';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { KundliComponent } from './components/kundli/kundli.component';
import { AstroMallComponent } from './components/astro-mall/astro-mall.component';
import { CategoryproductComponent } from './components/categoryproduct/categoryproduct.component';
import { SingleproductComponent } from './components/singleproduct/singleproduct.component';
import { BookingComponent } from './components/booking/booking.component';
import { AstrologerComponent } from './components/booking/astrologer/astrologer.component';
import { CheckoutComponent } from './components/booking/checkout/checkout.component';
import { QueryOrderListPersonComponent } from './components/orders/query-order-list-person/query-order-list-person.component';
import { ReportOrderListPersonComponent } from './components/orders/report-order-list-person/report-order-list-person.component';
import { ChatOrderListPersonComponent } from './components/orders/chat-order-list-person/chat-order-list-person.component';
import { AstroSubcategoryMallComponent } from './components/astro-subcategory-mall/astro-subcategory-mall.component';
import { SubcategoryProductComponent } from './components/subcategory-product/subcategory-product.component';
import { LoginUpdateComponent } from './components/login-update/login-update.component';
import { StoreOrderListComponent } from './components/orders/store-order-list/store-order-list.component';
import { StoreOrderDetailComponent } from './components/orders/store-order-detail/store-order-detail.component';
import { LightboxModule } from 'ngx-lightbox';
import { OngoingGroupchatCustomerComponent } from './components/ongoing-groupchat-customer/ongoing-groupchat-customer.component';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { BlogDetailsComponent } from './components/blog/blog-details/blog-details.component';
import { BlogListComponent } from './components/blog/blog-list/blog-list.component';
import { FestivalsListComponent } from './components/festivals-list/festivals-list.component';
import { FestivalDetailsComponent } from './components/festival-details/festival-details.component';
import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { RegisterPageMobileComponent } from './components/register-page-mobile/register-page-mobile.component';
import { MessageCeoComponent } from './components/message-ceo/message-ceo.component';
import { SwiperModule } from 'swiper/angular';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChatBotHeaderService } from './service/chat-bot/chat-bot-header.service';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { PolicyPageComponent } from './components/policy-page/policy-page.component';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { TopAstroComponent } from './components/top-astro/top-astro.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { ReportDailogComponent } from './components/order-add-person/report-dailog/report-dailog.component';
import { CustomerSupportComponent } from './components/customer-support/customer-support.component';
import { SupportDialogComponent } from './components/customer-support/support-dialog/support-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MyAddressComponent } from './components/my-address/my-address.component';
import { AddressDialogComponent } from './components/my-address/address-dialog/address-dialog.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { CartProductDetailsComponent } from './components/astro-order-cart/cart-product-details/cart-product-details.component';
import { CartDetailsComponent } from './components/astro-order-cart/cart-details/cart-details.component';
import { AstroSubcategoryProductComponent } from './components/astro-mall/astro-subcategory-product/astro-subcategory-product.component';
import { ListAllRemedyComponent } from './components/remedy/list-all-remedy/list-all-remedy.component';
import { CustomerDashboardComponent } from './components/customer-dashboard/customer-dashboard.component';
import { CustomerSidebarComponent } from './components/customer-sidebar/customer-sidebar.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ShowBlogListComponent } from './components/blog/show-blog-list/show-blog-list.component';
import { KundliShowComponent } from './components/kundli/kundli-show/kundli-show.component';
import { MatchMakingComponent } from './components/match-making/match-making.component';
import { PanchangComponent } from './components/panchang/panchang.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { GeneralPredictionComponent } from './components/general-prediction/general-prediction.component';
import { ChartsModule } from 'ng2-charts';
import { ShareChatComponent } from './components/share-chat/share-chat.component';
import { LoginFullpageComponent } from './components/login-fullpage/login-fullpage.component';
import { LayoutComponent } from './components/layout/layout.component';
import { TestLayoutComponent } from './components/layout/test-layout/test-layout.component';
import { WINDOW_PROVIDERS } from './service/window/window.service';
import { AddSupportDialogComponent } from './components/customer-support/add-support-dialog/add-support-dialog.component';
import { DynamicPageComponent } from './components/dynamic-page/dynamic-page.component';
import { BlogCategoriesComponent } from './components/blog-categories/blog-categories.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    CallAstrologerListComponent,
    VerifyPhoneOtpComponent,
    ForgetPasswordComponent,
    MyProfileComponent,
    AddPersonComponent,
    WalletTransactionComponent,
    WalletRechargeComponent,
    SinglePersonComponent,
    QueryOrderListComponent,
    QueryDetailsComponent,
    VerifyEmailOtpComponent,
    OrderAddPersonComponent,
    ShowReportListComponent,
    ReportOrderListComponent,
    ReportDetailsComponent,
    CallPlacedComponent,
    WaitingListComponent,
    CallOrderListComponent,
    CallDetailsComponent,
    AddWalletIssueComponent,
    IssueDetailComponent,
    IssueListComponent,
    ZodiacComponent,
    HoroscopeComponent,
    ChatDetailsComponent,
    ChatOrderListComponent,
    LoginComponent,
    RegisterComponent,
    DetailSupportComponent,
    AstrologerDetailsComponent,
    NotificationComponent,
    ChatPlacedComponent,
    VerifyPersonPhoneOtpComponent,
    OngoingChatCustomerComponent,
    ScrollToBottomDirective,
    ChatWalletRechargeComponent,
    ShowPromoAdminListComponent,
    AboutUsComponent,
    KundliComponent,
    AstroMallComponent,
    CategoryproductComponent,
    SingleproductComponent,
    BookingComponent,
    AstrologerComponent,
    CheckoutComponent,
    QueryOrderListPersonComponent,
    ReportOrderListPersonComponent,
    ChatOrderListPersonComponent,
    AstroSubcategoryMallComponent,
    SubcategoryProductComponent,
    LoginUpdateComponent,
    StoreOrderListComponent,
    StoreOrderDetailComponent,
    OngoingGroupchatCustomerComponent,
    BlogDetailsComponent,
    BlogListComponent,
    FestivalsListComponent,
    FestivalDetailsComponent,
    ContactPageComponent,
    RegisterPageMobileComponent,
    MessageCeoComponent,
    ComingSoonComponent,
    PolicyPageComponent,
    TopAstroComponent,
    ReportDailogComponent,
    CustomerSupportComponent,
    SupportDialogComponent,
    ConfirmDialogComponent,
    MyAddressComponent,
    AddressDialogComponent,
    NotificationsComponent,
    CartProductDetailsComponent,
    CartDetailsComponent,
    AstroSubcategoryProductComponent,
    ListAllRemedyComponent,
    CustomerDashboardComponent,
    CustomerSidebarComponent,
    ShowBlogListComponent,
    KundliShowComponent,
    MatchMakingComponent,
    PanchangComponent,
    GeneralPredictionComponent,
    ShareChatComponent,
    LoginFullpageComponent,
    LayoutComponent,
    TestLayoutComponent,
    AddSupportDialogComponent,
    DynamicPageComponent,
    BlogCategoriesComponent
  ],
  imports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    SelectDropDownModule,
    MatAutocompleteModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    MatSelectModule,
    MatRadioModule,
    NgCircleProgressModule.forRoot(),
    SwiperModule,
    MDBBootstrapModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    CommonModule,
    ToastrModule.forRoot(),
    NgOtpInputModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    // SlickCarouselModule,
    RouterModule,
    NgbCollapseModule,
    LightboxModule,
    NgbModalModule,
    SocialLoginModule,
    LazyLoadImageModule,
    ChartsModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    DatePipe,
    ConfirmDialogComponent,
    ChatBotHeaderService,
    MessagingService,
    AsyncPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '317093558149-k6loagm8ai1i8c3s06cfhnamis6tbk7i.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('587168169529498'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ReportDailogComponent, SupportDialogComponent],
})
export class AppModule { }
